/* RESPONSIBLE TEAM: team-knowledge-foundations */
import Model, { attr, belongsTo } from '@ember-data/model';
import type ContentImportRun from './content-import-run';
import { FailureReasonTypes } from './content-import-run';
import { EntityType } from '../data/entity-types';
import { post } from 'embercom/lib/ajax';
import { inject as service } from '@ember/service';
import type KnowledgeHubService from 'embercom/services/knowledge-hub-service';
import type IntlService from 'ember-intl/services/intl';
import type SyncSourceWrapper from 'embercom/models/knowledge-hub/sync-source-wrapper';

export enum SyncBehaviourTypes {
  AUTOMATIC = 'automatic',
  MANUAL = 'manual',
  API = 'api',
}

export enum ActorName {
  WebsiteContentCrawler = 'website_content_crawler',
  ReactComponentCrawler = 'react_component_crawler',
  DatedContentCrawler = 'dated_content_crawler',
}

export interface ActorData {
  actor_name: ActorName;
  relative_start_date?: number;
  date_selector?: string;
  include_pages_with_no_date?: boolean;
  remove_elements_css_selector?: string;
  filter_by_document_last_modified?: boolean;
}

export default class ContentImportSource extends Model {
  @service declare appService: any;
  @service declare knowledgeHubService: KnowledgeHubService;
  @service declare notificationsService: any;
  @service declare intl: IntlService;

  @attr('string') declare url: string;
  @attr('string') declare status: 'active' | 'deactivated' | 'deleted';
  @attr('number') declare contentCount: number;
  @attr('number') declare publishedContentCount: number;
  @attr('array') declare contentSegmentIds: number[];
  @attr('date') declare lastSyncedAt: Date;
  @attr('string') declare syncBehavior: SyncBehaviourTypes;
  @attr('boolean') declare useSitemap: boolean;
  @attr('array') declare additionalUrls: string[];
  @attr('array') declare excludeUrlGlobs: string[];
  @attr('array') declare removeCssSelectors: string[];
  @attr('array') declare clickCssSelector?: string;
  @attr('array') declare waitForSelector?: string;
  @attr('string') declare actorName: string;
  @attr declare actorData: ActorData;
  @belongsTo('content-service/content-import-run', { async: false })
  declare latestImportRun?: ContentImportRun;
  @belongsTo('content-service/content-import-run', { async: false })
  declare previousImportRun?: ContentImportRun;

  get isInUse(): boolean {
    return this.status !== 'deleted' && this.status !== 'deactivated';
  }

  get entityType(): number {
    return EntityType.ContentImportSource;
  }

  get canManuallySync(): boolean {
    return (
      this.syncBehavior === SyncBehaviourTypes.MANUAL &&
      !!this.latestImportRun &&
      this.latestImportRun?.isFullyIngested
    );
  }

  get canResyncFailure(): boolean {
    return (
      !!this.latestImportRun &&
      (this.didPreviousRunSucceed || !this.previousImportRun) &&
      [
        FailureReasonTypes.APIFY_RUN_FAILED,
        FailureReasonTypes.CONTENT_IMPORT_FAILED,
        FailureReasonTypes.FAILED_TO_ENQUEUE_RUN,
        FailureReasonTypes.NO_IMPORTED_CONTENT,
      ].includes(this.latestImportRun?.failureReason)
    );
  }

  get didPreviousRunSucceed(): boolean {
    return !!this.previousImportRun && this.previousImportRun.isFullyIngested;
  }

  get wasSyncedOverAWeekAgo(): boolean {
    //7 * 24 * 60 * 60 * 1000 = 604800000 which is 7 days in milliseconds
    return (
      this.lastSyncedAt !== null &&
      this.lastSyncedAt !== undefined &&
      new Date().getTime() - this.lastSyncedAt.getTime() >= 604800000
    );
  }

  get truncatedUrl(): string {
    return this.url.length < 45 ? this.url : this.url.slice(0, 42).concat('...');
  }

  get shouldShowOpenSettingsButton(): boolean {
    return this.actorName !== ActorName.ReactComponentCrawler;
  }

  get relativeDateMonths(): number | undefined {
    let timeInSeconds = this.actorData?.relative_start_date;
    if (!timeInSeconds) {
      return undefined;
    }
    let timeInMonths = timeInSeconds / (30 * 24 * 60 * 60);
    return Math.round(timeInMonths);
  }

  get dateSelector(): string | undefined {
    return this.actorData?.date_selector;
  }

  get includePagesWithNoDate(): boolean | undefined {
    return this.actorData?.include_pages_with_no_date;
  }

  setActorData(actorData: ActorData) {
    this.actorData ||= { actor_name: actorData.actor_name };
    this.actorData.actor_name = actorData.actor_name;
    this.actorData.relative_start_date = actorData.relative_start_date;
    this.actorData.date_selector = actorData.date_selector;
    this.actorData.include_pages_with_no_date = actorData.include_pages_with_no_date;
  }

  async resync(): Promise<void> {
    await post('/ember/content_service/content_import_sources/resync', {
      app_id: this.appService.app.id,
      admin_id: this.appService.app.currentAdmin.id,
      id: this.id,
    });
  }

  async triggerResync() {
    try {
      await this.resync();
      this.notificationsService.notifyConfirmation(
        this.intl.t('knowledge-hub.filterable-list.sources.actions.resync-start'),
      );
    } catch (error) {
      if (error.jqXHR.status === 409) {
        this.notificationsService.notifyWarning(
          this.intl.t('knowledge-hub.filterable-list.sources.actions.resync-already-running'),
        );
        return;
      }
      this.notificationsService.notifyError(
        this.intl.t('knowledge-hub.filterable-list.sources.actions.resync-error'),
      );
    }
  }

  async remove({ notify = true } = {}) {
    try {
      await this.knowledgeHubService.removeImportSource(this);

      let sources = this.store.peekAll('knowledge-hub/sync-source-wrapper');
      let sourceToDelete = sources.filter(
        (src: SyncSourceWrapper) => src.sourceId.toString() === this.id,
      )[0];
      sourceToDelete.deleteRecord();
      sourceToDelete.unloadRecord();

      if (notify) {
        this.knowledgeHubService.notifySourceStateChangeConfirmation(
          this.intl.t('knowledge-hub.filterable-list.sources.actions.remove-done'),
          `${this.id}`,
        );
      }
    } catch (error) {
      if (notify) {
        let errorMessage =
          error.jqXHR.status === 409
            ? this.intl.t('knowledge-hub.filterable-list.sources.actions.delete-conflict-error')
            : this.intl.t('knowledge-hub.filterable-list.sources.actions.remove-error');
        this.notificationsService.notifyError(errorMessage);
      }
    }
  }
}
